import React, { useEffect, useMemo, useState } from 'react';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    Spinner, 
    Row, 
    Nav, 
    NavItem, 
    NavLink, 
    TabContent, 
    TabPane, 
    Accordion, 
    AccordionBody, 
    AccordionItem, 
    AccordionHeader,
    Input,
    Button,
    Col
} from 'reactstrap';
import useClass from '../../hooks/useClass';
import { 
    additionalDocList, 
    entityData, 
    entityDirectorsData, 
    entityDocumentsData, 
    fieldStatusConstants, 
    fieldVerificationDropdown, 
    reKycFieldData, 
    rupeeSymbol
} from '../../utils/constants';
import DocModal from '../../components/Document-Viewer/DocModal';
import Dropdown from '../../components/Dropdown/Dropdown';
import ActionDropdown from '../../components/Dropdown/ActionDropdown';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBureauReport } from '../../store/loan/loanAction';
import CreditScore from './credit-analysis/CreditScore';
import RevenueBreakup from './Modal/RevenueBreakup';

const Entity = ({
    entityFinancialData, 
    isRekycOpen, 
    handleRekycFieldsSelection, 
    reKycFields, 
    handleFieldAction, 
    entityDetails
}) => {

    const { toaster, dispatch } = useClass();
    const { fieldStatus } = useSelector((state) => (state?.actionFields));
    const { loading, error, report } = useSelector((state) => (state?.bureauReport))
    const [open, setOpen] = useState('');
    const [activeDirectorTab, setActiveDirectorTab] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [viewedDoc, setViewedDoc] = useState({});
    const [addtionalDoc, setAdditionalDoc] = useState([]);
    const [isAdditionalDocButtonClicked, setIsAdditionalDocButtonClicked] = useState(false);
    const [viewCreditReport, setViewCreditReport] = useState(false);
    const [viewedDirector, setViewedDirector] = useState('');
    const [openRevenueBreakup, setOpenRevenueBreakup] = useState(false);
    const [revenueBreakup, setRevenueBreakup] = useState([]);
    const [topDebtors, setTopDebtors] = useState({});
    const [activeTopDebtorsTab, setActiveTopDebtorsTab] = useState(0);
    const [activeTopPartnersTab, setActiveTopPartnersTab] = useState(0);

    useEffect(() => {
        if(!loading && !error && report && viewedDirector){
            setViewCreditReport(true);
        }
    }, [loading]);

    useEffect(() => {
        if(entityFinancialData?.length > 0){
            entityFinancialData?.forEach((obj) => {
                if(obj?.fieldKey === 'revenueBreakup'){
                    setRevenueBreakup(JSON.parse(obj?.fieldValue));
                } else if(obj?.fieldKey === 'debtorDetails'){
                    setTopDebtors(JSON.parse(obj?.fieldValue));
                }
            });
        }
    }, [entityFinancialData])

    const toggleDirectorTab = (tab) => {
        return () => {
          if(activeDirectorTab !== tab){
            setActiveDirectorTab(tab);
          }
        }
    }

    const toggleTopDebtorsTab = (tab) => {
        return () => {
            setActiveTopDebtorsTab(tab);
        }
    }

    const toggleTopPartnersTab = (tab) => {
        return () => {
            setActiveTopPartnersTab(tab);
        }
    }

    const toggle = (id) => {
        if (open === id) {
          setOpen('');
        } else {
          setOpen(id);
        }
    };

    const handleImageExpand = (link, label) => {
        return () => {
            window.open(link, '_blank');
            // setViewedDoc({link, label});
            // setIsOpen(true);
        }
    }

    const handleAdditionDocInput = (key, value) => {
        if(!addtionalDoc.includes(value?.value)){
            setIsAdditionalDocButtonClicked(false);
            setAdditionalDoc((prev) => ([...prev, value?.value]))
            handleRekycFieldsSelection(value?.value, true);
        } else {
            toaster.show(true, 'Document already selected');
            setIsAdditionalDocButtonClicked(false);
        }
    }

    const handleAdditionalDocButton = (type, key=null) => {
        return () => {
            if(type === 'add'){
                setIsAdditionalDocButtonClicked(true);
            } else {
                setAdditionalDoc(addtionalDoc?.filter((item) => item !== key))
                handleRekycFieldsSelection(key, false);
            }
        }
    }

    const fetchBureauOfDirector = (dir) => {
        return () => {
            setViewedDirector(dir?.name);
            dispatch(getBureauReport(dir?.code));
        }
    }

    const getDynamicKey = useMemo((key) => {
        switch(key) {
            case '':
                return ''
            default: 
                return ''
        }
    }, [])

    return (
        <>
            <Card>
                <CardHeader>
                    <div className='section-heading'>Entity Details</div>
                </CardHeader>
                <CardBody>
                    <Row>
                        {Object.keys(entityDetails)?.map((key, index) => {
                            if(entityData[key]){
                                return (
                                    <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>
                                        <div className='data-key ms-4 gap-4'>
                                            <span>
                                                {isRekycOpen &&
                                                    <Input
                                                        style={{border: !(reKycFieldData?.includes(key) && key != 'pan') && 'none' }}
                                                        className='rekyc-input' 
                                                        type='checkbox'
                                                        onChange={(e) => {handleRekycFieldsSelection(key, e.target.checked)}}
                                                        disabled={!(reKycFieldData?.includes(key) && key != 'pan')}
                                                        checked={reKycFields?.[key]}
                                                    />
                                                }
                                                {entityData[key]}
                                            </span>
                                        </div>
                                        <div className='data-value me-4'>
                                            <span>{entityDetails?.[key] || '-'}</span>
                                            {reKycFieldData?.includes(key) && 
                                                <ActionDropdown
                                                    color={fieldStatusConstants?.[fieldStatus?.[key]]?.color}
                                                    className={fieldStatusConstants?.[fieldStatus?.[key]]?.className}
                                                    options={fieldVerificationDropdown}
                                                    onClick={(action) => handleFieldAction(action, key)}
                                                />
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        {entityFinancialData?.map((obj, index) => {
                            if(entityData[obj?.fieldKey]){
                                return (
                                    <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>                 
                                        <div className='data-key ms-4'>
                                            <span>
                                                {(isRekycOpen) && 
                                                    <Input
                                                        style={{border: !reKycFieldData?.includes(obj?.fieldKey) && 'none'}}
                                                        className='rekyc-input' 
                                                        type='checkbox'
                                                        onChange={(e) => handleRekycFieldsSelection(obj?.fieldKey, e.target.checked)}
                                                        disabled={!reKycFieldData?.includes(obj?.fieldKey)}
                                                        checked={reKycFields?.[obj?.fieldKey]}
                                                    />
                                                }
                                                {entityData[obj?.fieldKey]}
                                            </span>
                                        </div>
                                        <div className='data-value me-4'>
                                            {rupeeSymbol?.includes(obj?.fieldKey) && '₹'} {obj?.fieldValue || '-'}
                                            {reKycFieldData?.includes(obj?.fieldKey) &&
                                                <ActionDropdown
                                                    color={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.color}
                                                    className={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.className}
                                                    options={fieldVerificationDropdown}
                                                    onClick={(action) => handleFieldAction(action, obj?.fieldKey)}
                                                />
                                            }
                                        </div>
                                    </div>
                                
                                )
                            }
                        })}
                        {entityDetails?.entityDoc?.length > 0 && entityDetails?.entityDoc?.map((obj, index) => {
                            if(entityDocumentsData?.[obj?.docType]) return (<div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>
                                <div className='data-key ms-4'>
                                    <span>
                                        {(isRekycOpen) && 
                                            <Input
                                                className='rekyc-input' 
                                                type='checkbox'
                                                onChange={(e) => handleRekycFieldsSelection(obj?.docType, e.target.checked)}
                                                checked={reKycFields?.[obj?.docType]}
                                            />
                                        }
                                        {entityDocumentsData?.[obj?.docType]}
                                    </span>
                                </div>
                                <div className='data-value me-4'>
                                    <span onClick={handleImageExpand(obj?.presignUrl, entityDocumentsData?.[obj?.docType])} className='clickable'>
                                        <i className='fas fa-external-link-alt' />
                                    </span>
                                    <ActionDropdown
                                        color={fieldStatusConstants?.[fieldStatus?.[obj?.docType]]?.color}
                                        className={fieldStatusConstants?.[fieldStatus?.[obj?.docType]]?.className}
                                        options={fieldVerificationDropdown}
                                        onClick={(action) => handleFieldAction(action, obj?.docType)}
                                    />
                                </div>
                            </div>
                        )
                        })}
                        {addtionalDoc?.map((key, index) => (
                            <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>
                                <Dropdown
                                    value={{label: entityDocumentsData?.[key], value: key}}
                                    customClass='addition-doc-dropdown'
                                    options={additionalDocList}
                                    handleInput={handleAdditionDocInput}
                                    name={'additionalDoc'}
                                    isClearable={false}
                                />
                                <Button 
                                    onClick={handleAdditionalDocButton('remove', key)}
                                    className='btn-close addtional-doc-button-remove'
                                />
                            </div>
                        ))}
                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                            {isAdditionalDocButtonClicked ? <Dropdown
                                customClass='addition-doc-dropdown'
                                options={additionalDocList}
                                handleInput={handleAdditionDocInput}
                                name={'additionalDoc'}
                            /> :
                            <Button 
                                onClick={handleAdditionalDocButton('add')} 
                                className='addtional-doc-button'
                                color='primary' 
                                outline
                            >
                                Request more doc
                            </Button>}
                        </div>
                    </Row>
                    <hr />
                    {entityDetails?.entityDirectors?.length > 0 && 
                        <Row className='ps-4 pe-4'>
                            <Col style={{fontWeight: 'bold'}} className='mb-2'>Directors</Col>
                            <Nav tabs>
                                {entityDetails?.entityDirectors?.map((director, index) => (
                                    <NavItem key={index}>
                                        <NavLink 
                                            style={{color: activeDirectorTab === index && 'green' }} 
                                            className={`${activeDirectorTab === index && 'active'} clickable`} 
                                            onClick={toggleDirectorTab(index)}
                                        >
                                          <i className="ri-user-line me-1 align-middle"> </i>{" "}
                                          {director?.name}
                                          {director?.kycStatus === 'VERIFIED' && <i style={{marginLeft: '0.4rem'}} className='fas fa-check-circle'></i>}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeDirectorTab} className="p-3">
                                <TabPane tabId={activeDirectorTab} id="home">
                                    <Row>
                                        {entityDetails?.entityDirectors && <>
                                            {Object.keys(entityDetails?.entityDirectors?.[activeDirectorTab])?.map((key, index) => {
                                                if(entityDirectorsData[key]){
                                                    return (
                                                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}> 
                                                            <div className='data-key ms-4'>
                                                                <span>
                                                                    {(isRekycOpen) && 
                                                                        <Input 
                                                                            style={{border: !reKycFieldData?.includes(key) && 'none'}}
                                                                            className='rekyc-input'  
                                                                            type='checkbox'
                                                                            onChange={(e) => handleRekycFieldsSelection(key, e.target.checked)}
                                                                            disabled={!reKycFieldData?.includes(key)}
                                                                        />
                                                                    }
                                                                    {entityDirectorsData[key]}
                                                                </span>
                                                            </div>
                                                            <div className='data-value me-4'>{rupeeSymbol?.includes(key) && '₹'} {entityDetails?.entityDirectors[activeDirectorTab]?.[key] || '-'}</div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                            {entityDetails?.entityDirectors?.[activeDirectorTab]?.kycStatus === 'VERIFIED' && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'> 
                                                <div className='data-key ms-4'>Bureau Report</div>
                                                {loading ? <div><Spinner size='sm' /></div> : <>
                                                    {entityDetails?.entityDirectors?.[activeDirectorTab]?.score ? <>
                                                        <div>
                                                            {entityDetails?.entityDirectors?.[activeDirectorTab]?.score}&nbsp;
                                                            <span>
                                                                <i onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeDirectorTab])} className='ri-eye-fill' />
                                                            </span>
                                                        </div>
                                                    </> : <Link onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeDirectorTab])} className='me-4'>View</Link>}
                                                </>}
                                            </div>}
                                            {entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                                <Accordion open={open} toggle={toggle} className='w-100'>
                                                    <AccordionItem>
                                                        <AccordionHeader targetId={'1'}>
                                                            Aadhaar 
                                                            <span onClick={() => handleImageExpand(entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc)} style={{marginLeft: "50%"}}>
                                                                <i className="ri-fullscreen-line" />
                                                            </span>
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId={`1`}>
                                                          <div className='w-100 mt-2'>
                                                            <iframe style={{width: "100%"}} src={entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc} alt="aadhaar PDF" />
                                                          </div>
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </div>}
                                            {entityDetails?.entityDirectors[activeDirectorTab]?.selfie && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                                <Accordion open={open} toggle={toggle} className='w-100'>
                                                    <AccordionItem>
                                                        <AccordionHeader targetId={'1'}>
                                                            Selfie
                                                            <span onClick={() => handleImageExpand(entityDetails?.entityDirectors[activeDirectorTab]?.selfie)} style={{marginLeft: "50%"}}>
                                                                <i className="ri-fullscreen-line" />
                                                            </span>
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId={`1`}>
                                                          <div className='w-100 mt-2'>
                                                            <img className='w-100' src={entityDetails?.entityDirectors[activeDirectorTab]?.selfie} alt='Selfie' />
                                                          </div>
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </div>}
                                        </>}
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                    }
                    {topDebtors?.length > 0 && 
                        <>
                        <hr />
                        <Row className='ps-4 pe-4'>
                            <Col style={{fontWeight: 'bold'}} className='mb-2'>Top Debtors</Col>
                            <Nav tabs>
                                {topDebtors?.map((debtor, index) => (
                                    <NavItem key={`topDebtors${index}`}>
                                        <NavLink 
                                            style={{color: activeTopDebtorsTab === index && 'green' }} 
                                            className={`${activeTopDebtorsTab === index && 'active'} clickable`} 
                                            onClick={toggleTopDebtorsTab(index)}
                                        >
                                          {debtor?.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeTopDebtorsTab} className="p-3">
                                <TabPane tabId={activeTopDebtorsTab} id="topdebtors">
                                    <Row>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Type of loan</div>
                                            <div className='data-value'>{topDebtors?.[activeTopDebtorsTab]?.typeOfLoan}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Total principle amount</div>
                                            <div className='data-value'>₹ {topDebtors?.[activeTopDebtorsTab]?.totalPrincipleAmount}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Total outstanding</div>
                                            <div className='data-value'>₹ {topDebtors?.[activeTopDebtorsTab]?.totalOutstanding}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Total loan amount</div>
                                            <div className='data-value'>₹ {topDebtors?.[activeTopDebtorsTab]?.totalLoanAmount}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>EMI amount</div>
                                            <div className='data-value'>₹ {topDebtors?.[activeTopDebtorsTab]?.emiAmount}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Loan tenure</div>
                                            <div className='data-value'>{topDebtors?.[activeTopDebtorsTab]?.loanTenure} months</div>
                                        </div>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                        </>
                    }
                    {revenueBreakup?.length > 0 && 
                        <>
                        <hr />
                        <Row className='ps-4 pe-4'>
                            <Col style={{fontWeight: 'bold'}} className='mb-2'>Top Partners</Col>
                            <Nav tabs>
                                {revenueBreakup?.map((obj, index) => (
                                    <NavItem key={`topPartners${index}`}>
                                        <NavLink 
                                            style={{color: activeTopPartnersTab === index && 'green' }} 
                                            className={`${activeTopPartnersTab === index && 'active'} clickable`} 
                                            onClick={toggleTopPartnersTab(index)}
                                        >
                                          {obj?.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeTopPartnersTab} className="p-3">
                                <TabPane tabId={activeTopPartnersTab} id="toppartners">
                                    <Row>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Type of vehicle deployed</div>
                                            <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.typeOfVehicleDeployed}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Manufacturer</div>
                                            <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.manufacturer}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Number of vehicle deployed</div>
                                            <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.numberOfVehicleDeployed}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Avg. Kms driven/month</div>
                                            <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.avgKmDrivenPerMonth}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Contract Value</div>
                                            <div className='data-value'>₹ {revenueBreakup?.[activeTopPartnersTab]?.contractValue}</div>
                                        </div>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                        </>
                    }
                </CardBody>
            </Card>
            <DocModal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen}
                size={'xl'}
                label={viewedDoc?.label}
                link={viewedDoc?.link}
            />
            <CreditScore
                isOpen={viewCreditReport}
                setIsOpen={setViewCreditReport}
                name={viewedDirector}
            />
            <RevenueBreakup
                isOpen={openRevenueBreakup}
                setIsOpen={setOpenRevenueBreakup}
                data={revenueBreakup}
            />
        </>
    )
}

export default Entity;
