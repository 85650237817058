import React from 'react';
import { Modal } from 'reactstrap';
import { useSelector } from 'react-redux';
import { masterDataKey } from '../../../utils/constants';
import { formatDate2 } from '../../../utils/helper';

const ApplicationLogs = ({isOpen, setIsOpen}) => {

    const {data, loading, error} = useSelector((state) => (state?.applicationLogs));

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {setIsOpen(!isOpen)} }
                wrapClassName="modal-right"
            >
                <div className="modal-header pb-0">
                    Application Logs - {data?.[0]?.applicationId}
                    <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className='ms-4'>
                    <div className="wizard-order">
                        <div className="v-line"></div>
                        {data?.map((obj, index) => (
                            <>
                                <div className="step completed">
                                  <div className='ml-2 data-key' style={{minWidth: '100%'}}>Changed data: {masterDataKey?.[obj?.field]}</div>
                                  <div className='ml-2 data-value' style={{minWidth: '100%'}}>To: {obj?.value}</div>
                                  <div className='ml-2' style={{fontSize: '10px'}}>On: {formatDate2(obj?.createdAt)}, By: {obj?.createdBy === 'Admin User' ? 'Customer' : obj?.createdBy}</div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ApplicationLogs;
