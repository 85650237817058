import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        // {
        //     name: <span className='font-weight-bold fs-13'>SR No.</span>,
        //     selector: row => row?.index || '-',
        //     sortable: false
        // },
        {
            name: <span className='font-weight-bold fs-13'>Battery Make</span>,
            selector: row => row?.make || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Battery Code</span>,
            selector: row => (permission?.BATTERY?.DETAILS?.VIEW_BATTERY_DETAILS ? <Link to={`/battery/details/${row?.code}`}>{row?.code || '-'}</Link> : row?.code),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Capacity (kWh)</span>,
            selector: row => row?.capacity || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Battery Type</span>,
            selector: row => row?.type || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Warranty</span>,
            selector: row => row?.warranty || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Description</span>,
            selector: row => row?.description || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        }
    ];

    return data;
}

export default columns;
