import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helper';

const columns =  (permission) => {
    const data = [
    // {
    //     name: <span className='font-weight-bold fs-13'>SR No.</span>,
    //     selector: row => row.index,
    //     sortable: false
    // },
    {
        name: <span className='font-weight-bold fs-13'>User Name</span>,
        selector: row => (permission?.USERS?.DETAILS?.VIEW_USER_DETAILS ? <Link to={`/users/details/${row?.id}`}>{row.name}</Link> : row?.name),
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Email ID</span>,
        selector: row => row.email,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Type</span>,
        selector: row => row.type,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Organisation</span>,
        selector: row => row.org,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Is Active</span>,
        sortable: false,
        selector: (row) => {
            switch (`${row?.isActive}`) {
                case "false":
                    return <span className="badge badge-soft-danger"> {`${row?.isActive}` || '-'} </span>;
                case "true":
                    return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                default:
                    return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
            }
        },
    },
    {
        name: <span className='font-weight-bold fs-13'>Create Date</span>,
        selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
        sortable: false
    },
];

    return data;
}

export default columns;
