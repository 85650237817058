import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        // {
        //     name: <span className='font-weight-bold fs-13'>SR No.</span>,
        //     selector: row => row?.index || '-',
        //     sortable: false
        // },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => (permission?.HOT_LEADS?.DETAILS?.VIEW_HOT_LEAD_DETAILS ? <Link to={`/hot_leads/details/${row?.id}`}>{row?.name || '-'}</Link> : row?.name),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Code</span>,
            selector: row => row?.code || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Product Name</span>,
            selector: row => row?.application?.productName || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Mobile Number</span>,
            selector: row => row?.mobile || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Assisted Link</span>,
            selector: row => (permission?.HOT_LEADS?.LIST?.CREATE_NEW_HOT_LEAD ? <a href={row?.assistedUrl} target="_blank" rel="noopener noreferrer">{'Journey Link' || '-'}</a> : 'Link'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createAt).toLocaleDateString() || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row?.status) {
                    case "INCOMPLETE":
                        return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Assigned To</span>,
            selector: row => (row?.assignedTo || '-'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Team</span>,
            selector: row => (row?.teamName || '-'),
            sortable: false
        },
    ];

    return data;
}

export default columns;
